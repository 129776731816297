<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getUnAdjustReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 id="productNonAdjustTitle" class="col-md-10 col-8 font-weight-normal">
              {{ $t('report.txtnonadjust') }}
            </h2>
            <div v-if="isExport" class="col-md-2 col-4 text-right text-success">
              <CButton id="productNonAdjustExportButton" v-on:click="exportUnsellReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>

          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <sortdata
                v-on:getSort="setSort"
                :sort_value="sort_value"
                :sortdata="sortdata"
              />
              <hr />
              <DataTable
                id="productNonAdjustDatatable"
                style="white-space: nowrap;"
                :items="items"
                :fields="fields"
                hover
                border
                :loading="loading"
              >
                <template #unit="{item}">
                  <td style="vertical-align: middle;" class="text-dark">
                    {{ item.SKURatio }} / {{ item.unit }}
                  </td>
                </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getUnAdjustReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import report from '@/services/report'
import local from '@/services/service'
import Pagination from '@/containers/Pagination'
import Sortdata from '@/containers/SortData'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import util from '@/util/util'

export default {
  components: {
    Pagination,
    Sortdata,
    DataTable,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      sortOrder: 'asc',
      sortdata: 'ProductPLU.name',
      sort_value: {
        name: 'ProductPLU.name',
        category: 'category.name',
        onhandQtyMin: 'onhandQtyMin',
        onhandQtyMax: 'onhandQtyMax',
        PLUCode: 'ProductPLU.PLUCode',
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata

        if (sortdata == 'onhandQtyMin' || sortdata == 'onhandQtyMax') {
          sortdata = 'ProductPLUStock.onhandQty'
        }
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('#'),
          _classes: 'text-center text-dark font-weight-normal',
          _style: 'width:5%',
        },
        { key: 'PLUCode', label: this.$i18n.t('pluCode'), _classes: 'text-dark font-weight-normal' },
        {
          key: 'SKUName',
          label: this.$i18n.t('productName'),
          _style: 'width:35%',
          _classes: 'text-dark font-weight-normal'
        },
        { key: 'categoryName', label: this.$i18n.t('category'), _classes: 'text-dark font-weight-normal' },
        { key: 'unit', label: this.$i18n.t('unit'), _style: 'width:10%', _classes: 'text-dark font-weight-normal' },
        // { key: 'stdCost', label: this.$i18n.t('stdCost'), _classes: 'text-right' },
        {
          key: 'onhandQty',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:10%',
        },
      ]
    },
    items() {
      let data = this.data
      let detail = []
      let start = this.meta_data.current_page * 50 - 50

      //ProductPLU.SKURatio
      for (let i = 0; i < data.length; i++) {
        let onhandQty = '-'
        let stdCost = '-'
        let PLUCode = '-'
        let unit = '-'
        let SKURatio = '-'
        if (data[i].ProductPLUStock == undefined) {
          onhandQty = '-'
        } else {
          if (data[i].ProductPLUStock.onhandQty == undefined) {
            onhandQty = '-'
          } else {
            onhandQty = util.convertOnhand(data[i].ProductPLUStock.onhandQty)
          }
        }

        if (data[i].stdCost === undefined) {
          stdCost = '-'
        } else {
          stdCost = data[i].stdCost
        }

        if (data[i].ProductPLU === undefined) {
          PLUCode = '-'
          SKURatio = '-'
        } else {
          PLUCode = data[i].ProductPLU.PLUCode
          if (data[i].ProductPLU.SKURatio === undefined) {
            SKURatio = '-'
          } else {
            SKURatio = data[i].ProductPLU.SKURatio
          }
        }
        if (data[i].ProductPLU.unit === undefined) {
          unit = '-'
        } else {
          unit = data[i].ProductPLU.unit.name
        }

        detail.push({
          id: start + i + 1,
          PLUCode: PLUCode,
          SKUName: data[i].ProductPLU.name,
          categoryName: data[i].category.name || 'N/A',
          stdCost: stdCost,
          onhandQty: onhandQty,
          unit: unit,
          SKURatio: SKURatio,
        })
      }
      return detail
    },
  },
  created() {
    this.getUnAdjustReport()
  },
  methods: {
    setSort(value) {
      this.sortdata = value
      this.getUnAdjustReport()
    },
    getUnAdjustReport(page = 1) {
      this.loadingButton = false
      const uid = this.uid
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      let sortOrder = ''
      if (this.sortdata == 'onhandQtyMin') {
        sortOrder = 'asc'
      } else if (this.sortdata == 'onhandQtyMax') {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
        sortattr: this.sortAttr,
        sortorder: sortOrder,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      local({
        url: '/api/v1.0/' + uid + '/product/sku/unadjust',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportUnsellReport() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      report({
        url: '/api/v1.0/' + uid + '/product/sku/unadjust/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'รายงานสินค้าที่ยังไม่ปรับปรุงสต๊อก.xlsx'
          ) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
